<template>
  <div></div>
</template>

<script>
const axios = require("axios").default;
axios.defaults.withCredentials = false;
export default {
  data: () => ({}),
  // computed: {
  //   token() {
  //     return this.$store.state.user.token;
  //   }
  // },
  methods: {
    logout() {
      console.log("logging out");
      localStorage.removeItem("rtbjwt");
      this.$store.commit("login", {
        username: "",
        role: "",
        firstname: "",
        lastname: "",
        // notification: user.notification,
        phone: "",
        darkMode: ""
        // unread: user.unread,
      });
      this.$vuetify.theme.dark = false;
      this.$router.push({
        name: "Login"
      });
    }
  },
  mounted: function() {
    this.logout();
  }
};
</script>
